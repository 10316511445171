<template>
  <router-view></router-view>
</template>
<script setup>
import { ref, onMounted, nextTick, computed } from "vue";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from "vue-router";
import useAppConfig from "@/stores/appConfig";
import { isMobile } from "@/hooks/isMobile";

const router = useRouter();
const route = useRoute();
const appConfig = useAppConfig();
onMounted(() => {
  let path = location.href;
  if (isMobile()) {
    if (path.indexOf("mobile") <= -1) {
      router.replace("/mobile");
    }
  } else {
    if (path.indexOf("mobile") > -1) {
      router.replace("/");
    }
  }
});
</script>

<style lang="scss">
:root {
  --animate-duration: 3s;
}
* {
  margin: 0;
  padding: 0;
}
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
  color: transparent;
}

::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
  color: transparent;
}

.text-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-overflow2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
