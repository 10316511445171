<template>
  <el-config-provider :locale="locale">
    <div class="pageContent">
      <Header v-if="ua=='pc'"/>
      <div
        class="main"
        :style="ua=='pc'?'min-height: calc(100vh - 1rem)':''"
      >
        <router-view></router-view>
      </div>
      <!-- <Footer /> -->
    </div>
  </el-config-provider>
</template>

<script setup>
import { computed, ref, onMounted } from "vue";
import { isMobile } from "@/hooks/isMobile";
import { useRouter, useRoute } from "vue-router";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import en from "element-plus/dist/locale/en.mjs";
import Header from "./components/header.vue";
import Footer from "./components/footer.vue";
const router = useRouter();
const route = useRoute();
const language = ref("zh-cn");
const locale = computed(() => (language.value === "zh-cn" ? zhCn : en));
const ua = ref("pc");

onMounted(() => {
  let path = route.path;
  const inMobile = path.indexOf("mobile") > -1;
  const inPc = path.indexOf("mobile") <= -1;
  if (isMobile()) {
    ua.value = "mobile";
    if (inPc) {
      // router.push(path.replace("/", "/mobile"));
    }
  } else {
    ua.value = "pc";
    if (inMobile) {
      // router.push(path.replace("/mobile", "/"));
    }
  }
});
</script>

<style scoped lang="scss">
.main {
}
</style>