import { createApp } from 'vue'
import App from './App.vue'
import Vant from 'vant';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/style/iconfont.css'
import JsonViewer from 'vue-json-viewer'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import 'animate.css';
import { useFlexibleRem, useH52Rem } from "@/hooks/useFlexibleRem"; // 导入插件
import { isMobile } from "@/hooks/isMobile";
import { checkVisibility } from '@/hooks/checkVisibility'
import pinia from './stores'
import pages from './router/index'
import { useRouter, useRoute } from 'vue-router'
// 使用插件设置 REM 适配

if (isMobile()) {
    useH52Rem()


} else {
    useFlexibleRem();


}
const app = createApp(App)
function addAni(el, domId, animateName) {
    const visibility = checkVisibility(domId)
    if (visibility) {
        el.classList.add(animateName || 'pushIn', 'animated')
        el.style['animation-delay'] = `.2s`

    } else {

    }
}
app.directive('visibility', {
    mounted(el, bind) {
        const domId = el.getAttribute('id')
        const animateName = el.getAttribute('data-ani')
        addAni(el, domId, animateName)

        window.addEventListener("scroll", () => {
            addAni(el, domId, animateName)
        }); // 添加滚动事件监听器
    },
    unmounted(el) {
    }
})
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(pinia).use(pages).use(ElementPlus).use(Vant).mount('#app')
