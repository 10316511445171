import { get, post, getDynamicynamic, getFileUseBlobByPost } from './index'
export function apiCompanyProfile(params) {
    return get("/wozelen/companyProfile", params)
}
export function apiCompanyProfileId(id) {
    return get(`/wozelen/companyProfile/${id}`)
}
export function apiWozelenNews(params) {
    return get("/wozelen/news", params)
}
export function apiWozelenNewsId(id) {
    return get(`/wozelen/news/${id}`)
}
export function apiWozelenTalentRecruitment(params) {
    return get("/wozelen/talentRecruitment", params)
}
export function apiWozelenSolution(params) {
    return get("/wozelen/solution", params)
}
export function apiWozelenSolutionId(id) {
    return get(`/wozelen/solution/${id}`)
}
export function apiDictMap(params) {
    return post("/wozelen/dict/getDictMap", params,{
        "Content-Type": "application/json"
    })
}
export function apiWozelenProductAndService(params) {
    return get("/wozelen/productAndService", params)
}
export function apiWozelenProductAndServiceId(id) {
    return get(`/wozelen/productAndService/${id}`)
}
export function apiLeaveMessage(params) {
    return post("/wozelen/leaveMessage", params,{
        "Content-Type": "application/json"
    })
}
export function apiWozelenDevelopmentCourse(params) {
    return get(`/wozelen/developmentCourse`,params)
}
